<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <sweet-modal ref="createprojectmodal" @close="closeNested" title="Create Virtual Account">
            <a-form
                id="components-form-demo-normal-login"
                :form="form"
                class="login-form"
                @submit="handleSubmitProject"
                :hideRequiredMark="true"
                v-if="showNested"
            >
                <a-form-item class="mb-10" label="Name" :colon="false">
                    <a-input 
                    v-decorator="[
                    'name',
                    { rules: [{ required: true, message: 'Please input your virtual accountname!' }] },
                    ]" placeholder="Name" />
                </a-form-item>
                
                <a-form-item>
                    <a-button type="primary" block html-type="submit" class="login-form-button" :disabled="loading">
                        CREATE
                    </a-button>

                    <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

                    <p class="font-semibold text-muted text-center" v-if="error">{{ error }}</p>

                </a-form-item>
            </a-form>
        </sweet-modal>


        <vue-loaders-ball-beat v-if="loading" color="red" scale="1"></vue-loaders-ball-beat>

        <a-row>

            <a-col :span="20" class="mb-24">
                <!-- Header Search Input -->
					<a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Type here…" @search="onSearch" :loading='searchLoading'>
						<svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z" fill="#111827"/>
						</svg>
					</a-input-search>
            </a-col>

            <a-col :span="4" class="mb-24">
                <a-button id="v-step-1" type="primary" @click="initCreateProjectModal" block style="margin-left: 9px;">
                    Add Virtual Account
                </a-button>
            </a-col>

            <v-tour name="addTour" :steps="steps"></v-tour>
            
        </a-row>

		<!-- Authors Table -->
		<a-row :gutter="24" type="flex">


            <!-- Authors Table Column -->
            <a-col :span="24">
                <a-table :columns="accountsTableColumns" :data-source="accounts" :pagination="false" :loading="loading">
                    <span slot="name" slot-scope="text, record">
                        
                        <i class="fa fa-user-circle-o" aria-hidden="true" style="margin-right:9px;"></i>
                        <span class="ml-2">{{ record.name }}</span>
                    </span>
                    <span slot="key" slot-scope="text, record">
                        {{ record.key }}
                    </span>
                    
                    <span slot="created" slot-scope="text, record">
                        {{ record.created }}
                    </span>

                    <span slot="updated" slot-scope="text, record">
                        {{ record.updated }}
                    </span>


                    <span slot="actions" slot-scope="text, record">
                        <a-button type="primary">View</a-button>
                    </span>
                </a-table>

			</a-col>
			<!-- / Authors Table Column -->

		</a-row>
		<!-- / Authors Table -->

        <br>

	</div>
</template>

<script>

	// "Authors" table component.
	import CardProjectDisplay from '../components/Cards/CardProjectDisplay' ;

    import WidgetProject from '../components/Widgets/WidgetProject' ;


    import {fetchDataJSON, fetchPostJSON} from '../services/v1/services';
	

	export default ({
		components: {
    CardProjectDisplay,
    WidgetProject,
},
        beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
        async mounted() {
            const accountsRes = await fetchDataJSON('users/accounts/list', true, this.$store);

            this.accounts = accountsRes.data.accounts;

            if(this.accounts.length == 0){
                this.$tours['addTour'].start()
            }

            this.loading = false;

            console.log('mounted virtual accounts');
        },
        methods: {
            initCreateProjectModal() {
                this.showNested = true;
                this.$refs.createprojectmodal.open();
            },
            closeNested() {
                this.showNested = false;
            },
            async handleSubmitProject(e) {
                e.preventDefault();
                
                // validate form

                this.form.validateFields(async(err, values) => {
                    if ( !err ) {
                        console.log('Received values of form: ', values) ;

                        this.loading = true;

                        this.error = null;

                        const item = {
                            name: values.name,
                        };

                        const res = await fetchPostJSON('users/accounts/create_virtual_account',item, true, this.$store);

                        console.log(res);

                        this.$refs.createprojectmodal.close();

                        const accountsRes = await fetchDataJSON('users/accounts/list', true, this.$store);

                        this.accounts = accountsRes.data.accounts;

                        await this.$store.dispatch('loadAccountList', true);

                        this.loading = false;

                        this.$eventHub.$emit('openDrawer', {time: Date.now()});

                        this.newProjectName = '';
                    }
                });

            },
            async onSearch(value) {
                this.searchLoading = true;

                const accountsRes = await fetchDataJSON('users/accounts/list', true, this.$store);

                this.accounts = accountsRes.data.accounts;

                this.searchLoading = false;
            },

            async addProject() {

                this.loading = true;

                this.error = null;
                
                const projectCreateRes = await fetchPostJSON('accounts/create', true, {
                    name: this.newProjectName,
                    description: this.newProjectDescription,
                    type: this.selectedProjectType,
                }, this.$store);

                // this.accounts.push(projectRes.data.project);

                const accountsRes = await fetchDataJSON('users/accounts/list', true, this.$store);

                this.accounts = accountsRes.data.accounts;

                this.loading = false;

                this.$refs.createprojectmodal.close();

                this.$eventHub.emit('openDrawer', {time: Date.now()});

                this.newProjectName = '';
                this.newProjectDescription = '';
                this.selectedProjectType = 'full';
            },
        },
		data() {
			return {

                accounts: [],

                loading: true,

                error: null,

                searchLoading: false,

                showNested: false,

                projectTypeOptions: [
                    {
                        value: 'full',
                        label: 'Full Project RFM',
                    },
                    {
                        value: 'analytics',
                        label: 'Analytics Only',
                    }
                ],

                selectedProjectType: 'full',

                newProjectName: '',

                newProjectDescription: '',

                accountsTableColumns: [
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        scopedSlots: { customRender: 'name' },
                    },
                    {
                        title: 'Key',
                        dataIndex: 'key',
                        key: 'key',
                        scopedSlots: { customRender: 'key' },
                    },
                    {
                        title: 'Created',
                        dataIndex: 'created',
                        key: 'created',
                        scopedSlots: { customRender: 'created' },
                    },
                    
                ],

                searchTerm: '',

                steps: [
          		{
            		target: '#v-step-1',  // We're using document.querySelector() under the hood
            		content: `Add a new <strong>Virtual Account</strong>!`
          		},
				]
			}
		},
	})

</script>

<style lang="scss">

.selector-thick {
    width: 100%;

    height: 40px
}
</style>